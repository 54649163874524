@tailwind base;
@tailwind components;
@tailwind utilities;

.star-item {
  display: inline-block;
  margin-bottom: 5px;
}

.horizontal-slider {
  width: 100%;
  height: 25px;
}

.example-thumb {
  height: 25px;
  width: 25px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: pointer;
}

.example-track {
  top: 0;
  bottom: 0;
  background: #ddd;
  border-radius: 999px;
}

button.slick-arrow::before {
  font-size: 50px !important;
  color: yellow !important;

}

.slick-next {
  right: 35px !important;
  top: 45% !important;
}

.slick-prev {
  left: 10px !important;
  z-index: 10 !important;
  top: 45% !important;
}

.chart-center-title {
  text-align: center;
  font-size: 24px;
  /* Increase the font size */
  margin-top: 0px;
  margin-bottom: 40px;
  /* Add some margin for spacing */
}

.dropdown-container {
  display: flex;
  font-size: 20px;
  margin-bottom: 20px;
}